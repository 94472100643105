import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {ESignatureComponent} from './e-signature.component';
import {ExternalDocumentsService} from './e-signature.service';
import {ListCardModule} from '../list-card/list-card.module';
import {SharedModule} from '../../shared/shared.module';
import {PipesModule} from '../../pipes/pipes.module';
import {ESignatureCardComponent} from './e-signature.card';
import {ESignatureContainerComponent} from './e-signature-container/e-signature-container.component';
import {CommonCardModule} from '../common-card/common-card.module';
import { SendForSignatureModule } from '../send-for-signature/send-for-signature.module';
import { SignedDocumentSelectorModule } from "../signed-document-selector/signed-document-selector.module";

@NgModule({
  declarations: [
    ESignatureComponent,
    ESignatureCardComponent,
    ESignatureContainerComponent
  ],
  providers: [
    ExternalDocumentsService
  ],
  imports: [
    CommonModule,
    IonicModule,
    CommonCardModule,
    SendForSignatureModule,
    FormsModule,
    PipesModule,
    SharedModule,
    ListCardModule,
    SignedDocumentSelectorModule
],
})
export class ESignatureModule { }
