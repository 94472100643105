import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalsService} from '../../globals.service';
import {Goal, IGoal} from '../../models/goal';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import * as _ from 'lodash';
// import { ClientService } from '../../providers/client.service';
import { OnDestroy } from '@angular/core';
import { IndividualClientService } from 'src/app/providers/client.service';
import { IRating } from 'src/app/models/rating';

@Injectable({
  providedIn: 'root'
})
export class GoalService implements OnDestroy {

  private goals = new BehaviorSubject<Goal[]>([])
  goals$ = this.goals.asObservable()

  clientSubscription: Subscription

  constructor(private http: HttpClient,
              private globals: GlobalsService) {
  }

  ngOnDestroy(): void {
    this.clientSubscription.unsubscribe()
  }

  setGoals(goals: Goal[]) {
    console.log('Setting Goals: ', goals)
    if (goals?.length) {
      this.goals.next(goals.map(g => new Goal(g)))
    } else {
      this.goals.next([])
    }
  }


  getGoals(clientId = null): Goal[] {
    return Object.assign([], this.goals.getValue())
  }


  /**
   * Fetches all of a client's goals (program_id agnostic)
   * @param clientId: The client's ID
   * @param setGoals: Whether to update {@link goals}
   */
  fetchGoals(clientId: number, setGoals = true): Observable<Goal[]> {
    const url = `${this.globals.base_url}/goals?client_id=${clientId}`
    return this.http.get<Goal[]>(url).pipe(
      tap(goals => {
        if (setGoals) this.setGoals(goals)
      })
    )
  }


  /**
   * Creates a goal in the database
   * @param data: {program_id: Program ID, goals: Array of goals}
   */
  createGoal(data: {program_id: number, goals: IGoal[]}): Observable<Goal[]> {
    const url = `${this.globals.base_url}/goals`
    return this.http.post<Goal[]>(url, data).pipe(
      tap(newGoal => {
        const goals = [...this.getGoals(), ...newGoal]
        this.setGoals(goals)
      })
    )
  }


  /**
   * Updates a goal in the database
   * @param goal: The goal to be updated
   */
  updateGoal(goal: IGoal): Observable<Goal> {
    const url = `${this.globals.base_url}/goals/${goal.id}`
    return this.http.put<Goal>(url, goal).pipe(
      tap(newGoal => {
        let goals = this.getGoals()
        const index = _.findIndex(goals, {id: newGoal.id})
        if (index !== -1) goals.splice(index, 1, newGoal)
        goals = goals.map(g => new Goal(g))
        this.setGoals(goals)
      })
    )
  }


  /**
   * Marks the goal deleted while maintaining it in the database.
   * @param goal: The goal to be destroyed
   */
  destroyGoal(goal: IGoal): Observable<IGoal[]> {
    const url = `${this.globals.base_url}/goals/${goal.id}`
    return this.http.delete<IGoal[]>(url).pipe(
      tap(goalArray => {
        const goals = goalArray.map(g => new Goal(g))
        this.setGoals(goals)
      })
    )
  }

  hasCompletedHbcGoal() {
    return this.getGoals().filter(goal => goal.completed_at !== null && goal.goal_type === 'hbc' ).length > 0
  }

  findIncompleteHbcGoal() {
    return this.getGoals().filter(goal => goal.completed_at === null && goal.goal_type === 'hbc' )[0]
  }

  satisfiedScaleGoals(field: string, value: number) {
    return this.getGoals().filter(goal => {
      return (
        (
          goal.completed_at === null &&
          goal.field === field &&
          goal.direction === 'less_than' &&
          goal.value > value &&
          (goal.deleted_at === null || goal.deleted_at === undefined)
        ) || (
          goal.completed_at === null &&
          goal.field === field &&
          goal.direction === 'greater_than' &&
          goal.value < value &&
          (goal.deleted_at === null || goal.deleted_at === undefined)
        )
      )
    });
  }
}
