<ion-header class="pt-2">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="dismiss()">Close</ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">New In-Person E-Signature</ion-title>
    <ion-buttons slot="end">

    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="blue-purple-gradient montserrat">
  <e-signature
    [client]="client"
    [canBeSigned]="canBeSigned"
  ></e-signature>
</ion-content>
