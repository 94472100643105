import { IExternalDocument } from "./external_document";

export class IPdfDocument {
  id?: number;
  version?: number;
  metadata?: any;
  external_document_id?: number;
  external_document?: IExternalDocument;
  class_name?: string;
  visible_to_client?: boolean;
  recipient_email?: string;
  client_id?: number;
  recipient_name?: string;
  created_at?: string;
  name?: string;
}

export class PdfDocument implements IPdfDocument {
  id?: number;
  version?: number;
  client_id?: number;
  class_name?: string;
  className?: string;
  metadata?: any;
  external_document_id?: number;
  visible_to_client?: boolean;
  external_document?: IExternalDocument;
  recipient_email?: string;
  recipient_name?: string;
  canBeViewed?: boolean;
  name?: string;
  created_at?: string;
  badge?: {name: string, color?: string};
  signed_at?: string;
  date?: string;

  constructor(pdfDocument: IPdfDocument) {
    Object.entries(pdfDocument).forEach(entry => {
      this[entry[0]] = entry[1]
    })
    this.signed_at = this.created_at
    this.className = this.class_name
    this.canBeViewed = true
    this.date = this.created_at
    if (this.visible_to_client) {
      this.badge = {
        name: 'Visible To Client',
        color: 'warning'
      }
    }
  }

  getName(): string {
    return this.name
  }

  signerInfo(): string {
    return `Name: ${this.recipient_name} - Email: ${this.recipient_email} <br>Weeks: ${this.metadata['client.purchased_weeks']} - Amount: ${this.metadata['client.total_paid']}`
  }
}