import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Storage} from '@ionic/storage';
import {IEmployee} from './models/employee';
import {ILocation} from './models/location';
import {IUser, User} from './models/user';
import {ToastService} from './providers/toast.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  // tslint:disable-next-line:variable-name
  private current_user = new BehaviorSubject<User>({isManager: () => false});
  // tslint:disable-next-line:variable-name
  current_user$ : Observable<User> = this.current_user.asObservable();

  private employees = new BehaviorSubject<IEmployee[]>([]);
  employees$ = this.employees.asObservable();

  private locations = new BehaviorSubject<ILocation[]>([]);
  locations$ = this.locations.asObservable();

  // tslint:disable-next-line:variable-name
  // base_url = 'https://api.awaken180weightloss.com/api';
  // tslint:disable-next-line:variable-name
  // base_url = 'http://localhost:3000/api';
  // tslint:disable-next-line:variable-name
  // base_url ='https://api.awaken180testing.com/api';
  // tslint:disable-next-line:variable-name
  // base_url = 'https://api.awaken180weightlossforlife.com/api';
  base_url: string;

  currentUrl = new BehaviorSubject<string>('/');
  currentUrl$ = this.currentUrl.asObservable();

  previousUrl = new BehaviorSubject<string>('/');
  previousUrl$ = this.previousUrl.asObservable();

  constructor( private storage: Storage,
               private toastService: ToastService) {
    this.base_url = environment.baseUrl
  }

  /**
   * Sets the current user
   */
  setUser(user: any) : void {
    this.current_user.next(user)
  }


  /**
   * Returns immutable copy of the {@link current_user}
   */
  getUser() : IUser {
    return Object.assign({}, this.current_user.getValue())
  }

  isCurrentUserManager() : boolean {
    const user = this.getUser()
    if ( user.user_type && user.user_type <= 2 ) return true;
    return user.employee && user.employee.isManager;
  }

  isTrainer(): boolean {
    return this.isCurrentUserManager() || this.getUser().user_type <= 2.9
  }

  /**
   * Displays the universal loader.  Auto-timeout after 3s unless stay = true.
   * @param stay: Whether or not the loader should automatically close
   */
  showLoader(stay=false) : void {
    const container = document.querySelector('.loader') as HTMLElement
    container.style.display = 'block';

    if ( !stay ) {
      setTimeout(() => {
        this.hideLoader()
      }, 3000)
    }
  }


  /**
   * Returns immutable copy of {@link locations}
   */
  getLocations() {
    return Object.assign([], this.locations.getValue())
  }


  /**
   * Hides the universal loader
   */
  hideLoader() : void {
    const container = document.querySelector('.loader') as HTMLElement

    if ( container ) {
      container.style.display = 'none';
    }
  }


  /**
   * Sets the value for locations
   */
  setLocations( locations: ILocation[] ) : void {
    this.storage.set('locations', locations)
    this.locations.next(locations)
  }


  /**
   * Displays toast.
   * @param message message to show
   * @param stayOpen stay open or close automatically?
   * @param color Background color for the toast
   */
  handleResponse( message : string, stayOpen : boolean = false, color: string = 'danger' ) : void {
    this.toastService.handleResponse(message, stayOpen, color)
  }
}
