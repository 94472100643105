import { Injectable } from '@angular/core';
import {ToastButton} from '@ionic/core/dist/types/components/toast/toast-interface';
import {ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor( private toastCtrl: ToastController ) { }

  /**
   * Displays toast.
   * @param message message to show
   * @param stayOpen stay open or close automatically?
   * @param color Background color for the toast
   */
  handleResponse(message: string, stayOpen: boolean = false, color: string = 'danger', header: string = null) : void {
    let truncatedMessage = message;
    if (message && message.length > 1000) truncatedMessage = message.substr(0, 1000)

    const options : {
      message?: string,
      header?: string,
      duration?: number,
      position: 'top'|'bottom'|'middle',
      color: string,
      cssClass: string,
      mode?: 'ios'|'md',
      translucent?: boolean,
      buttons?: ToastButton[]
    } = {
      message: truncatedMessage,
      header: header,
      duration: stayOpen ? 0 : 3000,
      position: 'top',
      color,
      mode: 'ios',
      cssClass: 'toast-error'
    }
    if ( stayOpen ) {
      options.buttons = [{
        side: 'end',
        icon: 'close',
        role: 'cancel',
      }]
    }
    this.toastCtrl.create(options).then(toast => toast.present())
  }
}
