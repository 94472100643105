import {Injectable} from '@angular/core';
import {GlobalsService} from '../../../../../globals.service';
import {IClientDoc} from '../../../../../models/client_doc';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {IAWS_bucket_object, IDocument} from '../../../../../models/s3';
import {ISignedDocument, SignedDocument} from '../../../../../models/signed_document';
import {IMergedDocument, MergedDocument} from '../../../../../models/merged_documents';
import { IPdfDocument, PdfDocument } from 'src/app/models/pdf_document';

@Injectable()
export class ClientDocService {

  private mergedDocuments = new BehaviorSubject([] as any[]);
  mergedDocuments$: Observable<any[]> = this.mergedDocuments.asObservable()

  // tslint:disable-next-line:variable-name
  private client_docs = new BehaviorSubject([] as IClientDoc[]);
  client_docs$: Observable<IClientDoc[]> = this.client_docs.asObservable()

  private documents = new BehaviorSubject([] as IDocument[])
  documents$ = this.documents.asObservable()

  private signedDocuments = new BehaviorSubject([] as ISignedDocument[]|SignedDocument[])
  signedDocuments$ = this.signedDocuments.asObservable()

  private pdfDocuments = new BehaviorSubject([] as IPdfDocument[])
  pdfDocuments$ = this.pdfDocuments.asObservable()

  // tslint:disable-next-line:variable-name
  private AWS_docs = new BehaviorSubject([] as IAWS_bucket_object[])
  // AWS_docs$ = this.AWS_docs.asObservable()

  // tslint:disable-next-line:variable-name
  base_url: string;

  constructor( private globals: GlobalsService,
               private http: HttpClient ) {
    this.base_url = this.globals.base_url;
  }

  /**
   * Returns copy of {@link client_docs}
   */
  getClientDocs() : IClientDoc[] {
    return Object.assign([], this.client_docs.getValue())
  }


  /**
   * Updates BehaviorSubject {@link client_docs}
   * @param clientDocs: Documents to be set as {@link client_docs}
   */
  setClientDocs( clientDocs : IClientDoc[] ) : void {
    this.client_docs.next(clientDocs)
  }


  /**
   * Returns array copy of BehaviorSubject {@link documents}
   */
  getDocuments() : IDocument[] {
    return Object.assign([], this.documents.getValue())
  }


  /**
   * Updates BehaviorSubject {@link documents}
   * @param documents: Documents to set as {@link documents}
   */
  setDocuments( documents: IDocument[] ) : void {
    this.documents.next(documents)
  }

  getMergedDocuments(): IMergedDocument[] {
    return Object.assign([], this.mergedDocuments.getValue())
  }


  /**
   * Removes the document from {@link documents}
   * @param document: Document to remove
   */
  removeDocument( document : IDocument ) : void {
    const documents = _.pull(this.getDocuments(), document)
    this.setDocuments(documents)
  }


  /**
   * Returns cloned array version of {@link AWS_docs}
   */
  getAWSDocs() : IAWS_bucket_object[] {
    return Object.assign([], this.AWS_docs.getValue())
  }


  /**
   * Sets supplied docs to {@link AWS_docs}
   * @param docs: Array of AWS bucket objects
   */
  setAWSDocs( docs : IAWS_bucket_object[] ) : void {
    this.AWS_docs.next(docs)
  }


  /**
   * Adds supplied doc to clone of {@link AWS_docs} and updates the value.
   * @param doc: AWS bucket object
   */
  addToAWSDocs( doc : IAWS_bucket_object ) : void {
    const awsDocs = this.getAWSDocs()
    awsDocs.push(doc)
    this.setAWSDocs(awsDocs)
  }


  createClientDocIfMissing(params: IClientDoc): Observable<IClientDoc|void> {
    const currentDocs = this.getClientDocs()
    const existingDoc = _.find(currentDocs, {fileKey: params.key})

    if (!existingDoc) {
      return this.create(params)
    } else {
      return of(null)
    }
  }


  /**
   * Sends an email reminder to the client to sign the document
   * @param signed_document_id ID of the SignedDocument
   * @returns
   */
  sendReminder(signed_document_id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = `${this.globals.base_url}/signed_documents/${signed_document_id}/send_signature_reminder`
      this.http.post(url, {}).subscribe(
        (data: any) => {
          resolve(data)
        },
        (error: any) => {
          reject(error)
        }
      )
    })
  }


  /**
   * Adds supplied document to the end of {@link documents} and updates the BehaviorSubject {@link documents}
   * @param document: Document to be added to {@link documents}
   */
  addToDocuments(document : IDocument) : void {
    const documents = Object.assign([], this.getDocuments())
    documents.push(document)
    this.setDocuments(documents)
  }


  /**
   * Adds supplied client_doc and calls {@link setClientDocs} to update BehaviorSubject {@link client_docs}
   * @param clientDoc: Document to be added to {@link client_docs}
   */
  addToClientDocs( clientDoc : IClientDoc ) : void {
    const currDocs = this.getClientDocs()
    currDocs.push(clientDoc)
    this.setClientDocs(currDocs)
  }



  /**
   * Creates a new {@link IClientDoc} in the database and adds it to the BehaviorSubject {@link client_docs}
   * @param clientDoc: The document to be created
   * @param addToDocs: Whether to add it to the {@link client_docs}
   */
  create( clientDoc : IClientDoc, addToDocs: boolean = true ) : Observable<IClientDoc> {
    const url = `${this.base_url}/clients/${clientDoc.client_id}/client_docs`
    return this.http.post<IClientDoc>(url, clientDoc).pipe(
      tap( createdDoc => {
        if (addToDocs) this.addToClientDocs(createdDoc)
      })
    )
  }


  /**
   * Fetches client_docs from the database for the client with supplied client_id.
   * @param clientId: The client's ID
   * @param updateDocs: Whether or not to udpate the BehaviorSubject
   */
  fetchClientDocs( clientId : number, updateDocs: boolean = true ) : Observable<IClientDoc[]> {
    const url = `${this.base_url}/clients/${clientId}/client_docs`
    return this.http.get<IClientDoc[]>(url).pipe(
      tap( clientDocs => {
        clientDocs.forEach(doc => {
          doc.fileKey = decodeURIComponent(doc.fileKey)
        })

        if (updateDocs) {
          this.setClientDocs(clientDocs)
          this.setMergedDocuments([], clientDocs)
        }
      })
    )
  }

  /**
   * Fetches new client and signed documents from the database.
   * @param clientId: Client ID for the documents.
   * @param refresh: Whether or not to empty the documents first.
   */
  fetchClientAndSignedDocuments(clientId: number, refresh: boolean = true): Observable<[IClientDoc[], ISignedDocument[], IPdfDocument[]]> {
    // Clear all documents
    if (refresh) this.setDocuments([])

    // Fetch both client and signed documents
    const clientDocSub = this.fetchClientDocs(clientId, false)
    const signedDocSub = this.fetchSignedDocuments(clientId)
    const pdfDocSub = this.fetchPdfDocuments(clientId)

    // Call all at the same time and emit once
    return combineLatest([clientDocSub, signedDocSub, pdfDocSub])
  }


  setMergedDocuments(signedDocuments: ISignedDocument[] = [], clientDocuments: IClientDoc[] = [], pdfDocuments: IPdfDocument[] = []) {
    const documents = []

    clientDocuments.forEach(doc => {
      const mergedDoc: IMergedDocument = new MergedDocument(doc)
      mergedDoc.badge = {
        name: doc.visible_to_client ? 'Visible To Client' : ''
      }
      mergedDoc.date = doc.created_at
      mergedDoc.className = 'ClientDoc'
      mergedDoc.canBeViewed = true
      mergedDoc.canBeDeleted = true
      mergedDoc.fileType = doc.url.indexOf('.pdf') === -1 ? 'image' : 'pdf'
      documents.push(mergedDoc)
    })

    signedDocuments.forEach(doc => {
      const signedDoc = new SignedDocument(doc)
      let mergedDoc = signedDoc.convertToMergedDoc()
      mergedDoc = new MergedDocument(mergedDoc)
      mergedDoc.fileType = 'pdf'
      documents.push(mergedDoc)
    })

    pdfDocuments.forEach(doc => {
      const pdfDoc = new PdfDocument(doc)
      // let mergedDoc = pdfDoc.convertToMergedDoc()
      // mergedDoc = new MergedDocument(mergedDoc)
      // mergedDoc.fileType = 'pdf'
      documents.push(pdfDoc)
    })

    this.mergedDocuments.next(documents)
  }

  update(id: number, document: IClientDoc): Observable<IClientDoc> {
    const url = `${this.base_url}/client_docs/${id}`
    return this.http.put<IClientDoc>(url, document)
  }


  /**
   * Destroys the client_doc in the database and removes it from {@link client_docs}.
   * @param id: The client_doc ID
   */
  destroy( id : number ) : Observable<IClientDoc> {
    const url = `${this.base_url}/client_docs/${id}`
    return this.http.delete<IClientDoc>(url).pipe(
      tap(clientDoc => {
        this.reloadDocuments(clientDoc.client_id)
      })
    )
  }


  setSignedDocuments(documents: ISignedDocument[]|SignedDocument[], updateClientDocs: boolean = false): void {
    this.signedDocuments.next(documents)
    if (updateClientDocs) {
      const allDocs = []
      documents.forEach(document => {
        const doc = new SignedDocument(document)
        const clientDoc = doc.convertToMergedDoc()
        allDocs.push(clientDoc)
      })
      const existingClientDocs = _.filter(this.getClientDocs(), {className: 'ClientDoc'})
      this.setClientDocs([...existingClientDocs, ...allDocs])
    }
  }


  getSignedDocuments(): ISignedDocument[]|SignedDocument[] {
    return Object.assign([], this.signedDocuments.getValue())
  }


  /**
   * Returns array of signed documents for the given client.
   * @param clientId: The client's ID
   * @param updateDocs: Whether or not to update the BehaviorSubject
   */
  fetchSignedDocuments(clientId: number, updateDocs: boolean = true): Observable<ISignedDocument[]> {
    const url = `${this.base_url}/clients/${clientId}/signed_documents`
    return this.http.get<ISignedDocument[]>(url).pipe(
      tap(documents => {
        this.setSignedDocuments(documents, updateDocs)
      })
    )
  }


  fetchPdfDocuments(clientId: number, updateDocs: boolean = true): Observable<IPdfDocument[]> {
    const url = `${this.base_url}/clients/${clientId}/pdf_documents`
    return this.http.get<IPdfDocument[]>(url).pipe(
      tap(documents => {
        console.log(documents);

        if (updateDocs) this.setPdfDocuments(documents)
      })
    )
  }

  setPdfDocuments(documents: IPdfDocument[]): void {
    this.pdfDocuments.next(documents)
  }

  getPdfDocuments(): IPdfDocument[] {
    return Object.assign([], this.pdfDocuments.getValue())
  }


  /**
   * Attempts to download the signed document from the ESignGenie servers from our backend.
   * @param document: Document to be downloaded
   */
  downloadEsignGenieDocument(document: IClientDoc): Observable<{data: string}> {
    const url = `${this.base_url}/clients/${document.client_id}/signed_documents/${document.id}/download_pdf`
    return this.http.get<{data: string}>(url)
  }


  /**
   * Saves a signed document to the database
   * @param document: The document to be created
   * @param updateDocs: Whether or not to update the BehaviorSubject
   */
  createSignedDocument(document: ISignedDocument, updateDocs: boolean = true): Observable<ISignedDocument> {
    const url = `${this.base_url}/clients/${document.client_id}/signed_documents`
    return this.http.post<ISignedDocument>(url, document).pipe(
      tap(signedDocument => {
        this.setSignedDocuments([...this.getSignedDocuments(), signedDocument], updateDocs)
      })
    )
  }


  /**
   * Updates a signed document in the database
   * @param document: The document to be updated
   * @param updateDocs: Whether or not to update the BehaviorSubject
   */
  updateSignedDocument(document: ISignedDocument, updateDocs: boolean = true): Observable<ISignedDocument> {
    const url = `${this.base_url}/clients/${document.client_id}/signed_documents/${document.id}`
    return this.http.put<ISignedDocument>(url, document).pipe(
      tap(signedDocument => {
        this.setSignedDocuments([...this.getSignedDocuments(), signedDocument], updateDocs)
      })
    )
  }


  deletePdfDocument(document: PdfDocument): Observable<PdfDocument> {
    let url = `${this.globals.base_url}/pdf_documents/${document.id}`
    return this.http.delete<PdfDocument>(url).pipe(
      tap(() => this.reloadDocuments(document.client_id))
    )
  }


  /**
   * It will not destroy the actual document but will disconnect it from the client.
   * @param document: The signed document to be destroyed
   */
  unlinkSignedDocument(document: ISignedDocument): Observable<ISignedDocument> {
    const localDocument = Object.assign({}, document)
    const url = `${this.base_url}/clients/${document.client_id}/signed_documents/${document.id}`
    document.client_id = null

    return this.http.put<ISignedDocument>(url, document).pipe(
      tap(() => {
        this.reloadDocuments(localDocument.client_id)
      })
    )
  }

  reloadDocuments(clientId: number): void {
    this.fetchClientAndSignedDocuments(clientId, true).subscribe(
      ([clientDocs, signedDocs, pdfDocuments]) => {
        this.setMergedDocuments(signedDocs, clientDocs, pdfDocuments)
      }
    )
  }
}
