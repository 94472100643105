import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {IClient} from '../../../models/client';

@Component({
  selector: 'app-e-signature-container',
  templateUrl: './e-signature-container.component.html',
  styleUrls: ['./e-signature-container.component.scss'],
})
export class ESignatureContainerComponent implements OnInit {
  client: IClient;
  canBeSigned: boolean;

  constructor( private params: NavParams,
               private modalCtrl: ModalController ) { }

  ngOnInit() {
    this.client = this.params.get('client')
    console.log(this.params);
    console.log(this.params.get('canBeSigned'));

    this.canBeSigned = this.params.get('canBeSigned')
  }

  dismiss(): void {
    this.modalCtrl.dismiss()
  }

}
