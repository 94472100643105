import { IComment } from "./comment";
import { IRating } from "./rating";

export interface IGoal {
  description?: string;
  id?: number;
  completed_at?: string;
  created_at?: string;
  deleted_at?: string;
  program_id?: number;
  author_name?: string;
  re_evaluation_id?: number;
  goal_type?: string;
  field?: string;
  value?: number;
  direction?: string;
  rating?: IRating;
  comment?: IComment;
}

export class Goal implements IGoal {
  public description?: string;
  public id?: number;
  public completed_at?: string;
  public created_at?: string;
  public deleted_at?: string;
  public program_id?: number;
  public re_evaluation_id?: number;
  public author_name?: string;
  public goal_type?: string
  public field?: string;
  public value?: number;
  public direction?: string;
  public rating?: IRating;
  public comment?: IComment;

  constructor(goal: IGoal) {
    Object.entries(goal).forEach(entry => {
      this[entry[0]] = entry[1]
    })
  }

  goalTypeIcon() {
    return {
      fm_transition: 'fa-internal-scale',
      hbc: 'fa-internal-scale',
      internal: 'fa-internal-scale',
      external_scale: 'fa-external-scale',
      external_non_scale: 'fa-non-scale'
    }[this.goal_type]
  }
}
