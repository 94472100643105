<ion-item button *ngFor="let document of documents"
          (click)="selectDocument(document)">
  <ion-icon
    *ngIf="iconName"
    [color]="verifySelectedStatus(document) ? 'success' : ''"
    [name]="verifySelectedStatus(document) ? 'checkmark-circle' : 'radio-button-off'"
    slot="start"
  ></ion-icon>

  <ion-label class="ion-text-wrap">
    <h2 class="pb-1">
      <strong>{{document.getName()}}</strong>
    </h2>
    <p [innerHTML]="document.signerInfo()"></p>
  </ion-label>

  <ion-text slot="end">
    <small>Signed At: {{document.signed_at | date:'short'}}</small>
  </ion-text>
</ion-item>

