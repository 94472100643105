import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams, Platform} from '@ionic/angular';
import {WeighInsProvider} from '../../pages/clients/client-detail/providers/weigh-ins/weigh-ins.service';
import {IWeighIn, WeighIn} from '../../models/weigh-in';
import {GlobalsService} from '../../globals.service';
import {ClientService} from '../../pages/clients/clients.service';
import * as moment from 'moment';
import {BarcodeScanner} from '@awesome-cordova-plugins/barcode-scanner/ngx'
import * as _ from 'lodash';
import {VisitService} from '../../pages/clients/client-detail/providers/visits/visits.service';
import {AwakenModal} from '../../shared/awaken-modal/awaken-modal.component';
import {BarcodeScannerService} from '../barcode-scanner/barcode-scanner.service';
import { Client } from 'src/app/models/client';
import {GoalService} from '../goal/goal.service';
import {IGoal} from '../../models/goal';

@Component({
  selector: 'app-new-weigh-in',
  templateUrl: './new-weigh-in.component.html',
  styleUrls: ['./new-weigh-in.component.scss'],
})
export class NewWeighInComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  weigh_in: IWeighIn;
  barcodeData: any;
  cols:any;
  // tslint:disable-next-line:variable-name
  error_text:any;
  // tslint:disable-next-line:variable-name
  client_id:any;
  // tslint:disable-next-line:variable-name
  check_in_id:any;
  client:any;
  token: string;
  cols2:any;
  unchangedData:any;
  cols3:any;
  // tslint:disable-next-line:variable-name
  claim_status: string;
  weighInIndex: number;
  keys:any;
  success:boolean;
  partOfVisit = false;
  beginScanDisabled = false;

  constructor( private modalCtrl: ModalController,
               private weighInsProvider: WeighInsProvider,
               public clientService: ClientService,
               public plt: Platform,
               private goalService: GoalService,
               private visitService: VisitService,
               private scanner: BarcodeScanner,
               private barcodeScannerService: BarcodeScannerService,
               private params: NavParams,
               private globals: GlobalsService ) {
    this.partOfVisit = params.get('partOfVisit')
    this.weighInIndex = params.get('weighInIndex') || 0
    this.client = params.get('client') || this.clientService.getClient();
    this.client_id = params.get('client_id') || this.client?.id;
    this.check_in_id = params.get('check_in_id');
    this.claim_status = params.get('claim_status');
    this.cols2 = {
      ' ID'                          : 'scale_id',
      'Full Name'                    : 'name',
      'Date & Time'                  : 'date',
      'Weight (lb)'                  : 'current_weight',
      'Body Fat (%)'                 : 'body_fat',
      'Fat Mass (lb)'                : 'fat_mass',
      'Body Mass Index (BMI)'				 : 'bmi',
      'Fat Free Mass (lb)'           : 'fat_free_mass',
      phase_angle                  : 'phase_angle',
      'Visceral Fat Rating'          : 'visceral_fat',
      'Body Water (%)'               : 'body_water_percent',
      'Body Water (lb)'              : 'tbw',
      'Muscle Mass (lb)'             : 'muscle_mass',
      'Bone Mass (lb)'               : 'bone_mass',
      'Metabolic Age (yrs)'          : 'metabolic_age',
      'Right Leg Fat (%)'            : 'right_leg_fat_percent',
      'Right Leg Fat Mass (lb)'      : 'right_leg_fat_mass',
      'Right Leg Fat Free Mass (lb)' : 'right_leg_fat_free_mass',
      'Right Leg Muscle Mass (lb)'   : 'left_leg_muscle_mass',
      'Left Leg Fat (%)'             : 'right_leg_fat_percent',
      'Left Leg Fat Mass (lb)'       : 'left_leg_fat_mass',
      'Left Leg Fat Free Mass (lb)'  : 'left_leg_fat_free_mass',
      'Left Leg Muscle Mass (lb)'    : 'left_leg_muscle_mass',
      'Right Arm Fat (%)'            : 'right_arm_fat_percent',
      'Right Arm Fat Mass (lb)'      : 'right_arm_fat_mass',
      'Right Arm Fat Free Mass (lb)' : 'right_arm_fat_free_mass',
      'Right Arm Muscle Mass (lb)'   : 'right_arm_muscle_mass',
      'Left Arm Fat (%)'             : 'left_arm_fat_percent',
      'Left Arm Fat Mass (lb)'       : 'left_arm_fat_mass',
      'Left Arm Fat Free Mass (lb)'  : 'left_arm_fat_free_mass',
      'Left Arm Muscle Mass (lb)'    : 'left_arm_muscle_mass',
      'Trunk Fat (%)'                : 'trunk_fat_percent',
      'Trunk Fat Mass (lb)'          : 'trunk_fat_mass',
      'Trunk Fat Free Mass (lb)'     : 'trunk_fat_free_mass',
      'Trunk Muscle Mass (lb)'       : 'trunk_muscle_mass'
    }
    this.cols3 = [
      { name: 'current_weight', type: 'number', required: true },
      { name: 'body_fat', type: 'number', required: true },
      { name: 'visceral_fat', type: 'number', required: true },
      { name: 'fat_mass', type: 'number', required: false },
      { name: 'fat_free_mass', type: 'number', required: false},
      { name: 'body_water_percent', type: 'number', required: false },
      { name: 'tbw', type: 'number', required: false },
      { name: 'muscle_mass', type: 'number', required: false },
      { name: 'bone_mass', type: 'number', required: false }
    ]
    this.cols = [
      'ID',
      'Full Name',
      'Date & Time',
      'Weight (lb)',
      'Body Mass Index (BMI)',
      'Body Fat (%)',
      'Fat Mass (lb)',
      'Body Fat Range',
      'Fat Free Mass (lb)',
      'Visceral Fat Rating',
      'Body Water (%)',
      'Body Water (lb)',
      'Muscle Mass (lb)',
      'Bone Mass (lb)',
      'Muscle Score',
      'Basal Metabolic Rate (kcal)',
      'Right Leg Fat (%)',
      'Right Leg Fat Mass (lb)',
      'Right Leg Fat Free Mass (lb)',
      'Right Leg Muscle Mass (lb)',
      'Right Leg Impedance (?)',
      'Left Leg Fat (%)',
      'Left Leg Fat Mass (lb)',
      'Left Leg Fat Free Mass (lb)',
      'Left Leg Muscle Mass (lb)',
      'Left Leg Impedance (?)',
      'Right Arm Fat (%)',
      'Right Arm Fat Mass (lb)',
      'Right Arm Fat Free Mass (lb)',
      'Right Arm Muscle Mass (lb)',
      'Right Arm Impedance (?)',
      'Left Arm Fat (%)',
      'Left Arm Fat Mass (lb)',
      'Left Arm Fat Free Mass (lb)',
      'Left Arm Muscle Mass (lb)',
      'Left Arm Impedance (?)',
      'Trunk Fat (%)',
      'Trunk Fat Mass (lb)',
      'Trunk Fat Free Mass (lb)',
      'Trunk Muscle Mass (lb)',
      'Body Fat Goal (%)'
    ]
    this.barcodeData = {}
  }

  ngOnInit() : void {
    console.log(this);

  }

  ionViewWillEnter() : void {
    this.plt.ready().then(() => {
      if ( this.plt.is('ipad') ) {
        setTimeout(() => {
          this.beginScanningBarcode()
        }, 1000)

      } else {
        this.error_text = 'ionViewWillEnter'
        this.barcodeData = {client_id: this.client_id, check_in_id: this.check_in_id, date: moment().format()}
        this.parseBarcode(this.barcodeData);
      }
    })
  }


  /**
   * Opens the barcode scanner and assigns the result to {@link NewWeighInComponent#barcodeData}
   */
  beginScanningBarcode(attempts = 0) : void {
    if (!this.beginScanDisabled) {
      this.beginScanDisabled = true;
      this.globals.handleResponse('Beginning to scan...', false, 'primary')
      this.scanner.scan()
        .then(
          barcodeData => {
            this.beginScanDisabled = false
            this.error_text = 'attempting again'
            this.barcodeData = {}
            this.barcodeData = barcodeData.text

            // @ts-ignore - Tested and response contains {"cancelled": 1} and does not say true
            if (barcodeData.cancelled === 1) {
              this.error_text = 'barcodeData cancelled'
              this.barcodeData = {client_id: this.client_id, check_in_id: this.check_in_id, date: moment().format()}
            }

            this.parseBarcode(this.barcodeData);
          },
        err => {
          this.beginScanDisabled = false
          this.error_text = 'on rejection -- ' + JSON.stringify(err)
          this.globals.handleResponse(this.error_text, true)
          this.barcodeData = {client_id: this.client_id, check_in_id: this.check_in_id, date: moment().format()}
          this.parseBarcode(this.barcodeData);
        }
      ).catch(err => {
        this.beginScanDisabled = false
        this.globals.handleResponse(`Catch: ${err}`, true)
      })
    }
  }


  /**
   * Parses and formats the barcode scanner result into the desired format.
   * @param data Data from the scanner
   */
  parseBarcode(data) : void {
    if ( typeof(data) === 'string' ) {
      data = JSON.parse(data)
      this.unchangedData = data
      data = this.renameKeys(data, this.cols2)
    }

    data.client_id = this.client_id
    data.check_in_id = this.check_in_id

    if ( data.date ) {
      data.date = moment(data.date).format()
    } else {
      data.date = moment().format()
    }

    this.barcodeData = data
    this.keys = Object.keys(data)
  }


  renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map(key => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  }


  /**
   * Updates a client's goals, birthday, gender and a few other fields that are received from the scale if they are missing.
   */
  updateClientAttributes() : void {
    const dataAttributes = ['Gender', 'Full Name', 'Birthday', 'Height (ft-in)']
    const clientAttributes = ['gender', 'name', 'dob', 'height']
    const specialAttributes = _.filter([
      'body_fat_target',
      'body_fat_target2',
      'visceral_fat_target',
      'visceral_fat_target2'
    ], (field) => {
      return !this.client[field]
    })

    const finalData: {id: number, gender?: string, dob?: string} = {
      id: this.client_id
    }

    if ( this.unchangedData ) {
      for (const i in clientAttributes) {
        if (this.client && !this.client[clientAttributes[i]]) {
          finalData[clientAttributes[i]] = this.unchangedData[dataAttributes[i]]
          if ( finalData.gender ) finalData.gender = finalData.gender.toLowerCase()
        }
      }

      const gender = this.client.gender || finalData.gender
      let dob;

      if ( this.client.dob) { dob = moment(this.client.dob) }
      else { dob = moment(finalData.dob, 'M/D/YYYY') }

      const client = new Client(this.client);
      const age = moment().diff(dob, 'years')

      if ( specialAttributes.length > 0 && gender && age ) {
        for ( const field of specialAttributes ) {
          const val = client.fetchTarget(field)
          if (val) { finalData[field] = val }
        }
      }

      if (Object.keys(finalData).length > 1) {
        this.clientService.update(finalData).subscribe()
      }
    }
  }


  /**
   * Submits the changes from the form.
   */
  async submitWeighIn( continueToRepo : boolean = false ) {
    const currentWeight = this.barcodeData.current_weight || 0
    this.barcodeData.claim_status = this.claim_status;

    if (currentWeight <= 0 ) {
      this.modalCtrl.create({
        component: AwakenModal,
        componentProps: {
          type: 'alert',
          title: 'Incorrect Current Weight!',
          subtitle: 'Please do not submit a weigh in with a 0 weight. It will mess up company statistics. Simply close this screen and add your repositioning notes.'
        },
        cssClass: 'small-modal'
      }).then(modal => modal.present())
    } else {
      this.weighInsProvider.create(this.barcodeData).subscribe(
        async(data) => {
          await this.dismiss(continueToRepo)
          const weighInData = data.weigh_in

          if (weighInData.client_id) {
            this.updateClientAttributes()
            this.visitService.setWeighIn(weighInData)
          }

          const weighIn = new WeighIn(weighInData)
          const completedHbcGoal = this.goalService.hasCompletedHbcGoal()

          const completedScaleGoals: IGoal[] = [
            ...this.goalService.satisfiedScaleGoals('weight', weighIn.current_weight),
            ...this.goalService.satisfiedScaleGoals('body_fat', weighIn.body_fat)
          ]

          if (this.shouldShowNoAppInstallModal()) await this.launchNoAppInstallModal()

          if (data.warning) await this.launchUnacceptableGainModal(data.warning)

          if (weighIn.isAtHealthyBodyComposition(this.client) && !completedHbcGoal) {
            const goal = this.goalService.findIncompleteHbcGoal()
            if (goal) completedScaleGoals.push(goal)
          }

          await this.weighInsProvider.launchCompletedGoalsModals(completedScaleGoals)
        },
        err => {
          this.globals.handleResponse(err.error, true)
        }
      )
    }
  }

  shouldShowNoAppInstallModal(): boolean {
    return this.weighInIndex === 1 && !this.clientService.getClient()?.awaken_app_sign_in_at
  }

  async launchNoAppInstallModal(): Promise<void> {
    return  new Promise(async res => {
      const m = await this.modalCtrl.create({
        component: AwakenModal,
        componentProps: {
          title: 'No Client-Facing App Installed',
          subtitle: 'The client has not installed the client-facing app. Please help them install it before they leave.',
          formattedBody: `<img src="assets/app_install_qr_codes.png"></img>`,
          urgent: true
        },
        cssClass: 'medium-modal'
      });
      await m.present();
      await m.onDidDismiss();
      res()
    })
  }

  async launchUnacceptableGainModal(warning: {title: string, subtitle: string}): Promise<void> {
    return  new Promise(async res => {
      const m = await this.modalCtrl.create({
        component: AwakenModal,
        componentProps: {
          title: warning.title,
          subtitle: warning.subtitle,
          urgent: true
        },
        cssClass: 'small-modal'
      })
      await m.present()
      await m.onDidDismiss()
      res()
    })
  }

  /**
   * Closes the modal and potentially moves on to the repositioning.
   */
  dismiss(continueToRepo : boolean = false) {
    this.modalCtrl.dismiss({continueToRepo, checkInId: this.check_in_id, component: 'NewWeighInComponent'})
  }

}
