import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  DailyCheckInAnswer,
  DailyCheckInQuestion,
  IDailyCheckIn,
  IDailyCheckInAnswer,
  IDailyCheckinByQuestion,
  IDailyCheckinByQuestionAnswerResponse,
  IDailyCheckInQuestion,
  IDailyCheckInQuestionResponse,
  IDailyCheckInsResponse,
  IDailyCheckInTemplate,
  IDailyCheckInTemplateResponse
} from '../models/dailyCheckIn';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {GlobalsService} from '../globals.service';
import {environment} from '../../environments/environment';
import {IPagination} from '../models/pagination';

@Injectable({
  providedIn: 'root'
})
export class DailyCheckInsService {
  baseUrl = environment.client_facing_app_url;
  dailyCheckIns = new BehaviorSubject<IDailyCheckIn[]>([]);
  dailyCheckIns$ = this.dailyCheckIns.asObservable();

  dailyCheckInsByQuestion = new BehaviorSubject<IDailyCheckinByQuestion[]>([]);
  dailyCheckInsByQuestion$ = this.dailyCheckInsByQuestion.asObservable();

  dailyCheckInPagination = new BehaviorSubject<IPagination>({})
  dailyCheckInPagination$ = this.dailyCheckInPagination.asObservable()

  dailyCheckinBankPagination = new BehaviorSubject<IPagination>({})
  dailyCheckinBankPagination$ = this.dailyCheckInPagination.asObservable()

  dailyCheckinTemplate = new BehaviorSubject<IDailyCheckInTemplate>({})
  dailyCheckinTemplate$ = this.dailyCheckinTemplate.asObservable()

  dailyCheckinBankTemplate = new BehaviorSubject<IDailyCheckInTemplate>({})
  dailyCheckinBankTemplate$ = this.dailyCheckinTemplate.asObservable()

  numberOfResponses = new BehaviorSubject<number>(0)
  numberOfResponses$ = this.numberOfResponses.asObservable()


  constructor(private http: HttpClient,
              private globals: GlobalsService) {
                console.log(this);

              }

  setDailyCheckIns(dailyCheckIns: IDailyCheckIn[]) {
    dailyCheckIns?.forEach(dci => {
      dci.answers = dci.answers.map((a: IDailyCheckInAnswer) => new DailyCheckInAnswer(a))
    })
    this.dailyCheckIns.next(dailyCheckIns)
  }

  setDailyCheckinByQuestion(dailyCheckIns: IDailyCheckinByQuestion[]) {
    this.dailyCheckInsByQuestion.next(dailyCheckIns)
  }

  getDailyCheckinByQuestion(): IDailyCheckinByQuestion[] {
    return this.dailyCheckInsByQuestion.getValue()
  }

  getDailyCheckIns() {
    return Object.assign([], this.dailyCheckIns.getValue())
  }

  setDailyCheckInResponse(dailyCheckInResponse: IDailyCheckInsResponse): void {
    this.setDailyCheckIns(dailyCheckInResponse.result?.checkins)
    this.numberOfResponses.next(dailyCheckInResponse.result?.number_of_responses)
    this.setDailyCheckinByQuestion(dailyCheckInResponse.result?.questions)
    this.setDailyCheckinPagination(dailyCheckInResponse.metadata.pagination)
  }

  setDailyCheckinPagination(pagination: IPagination): void {
    this.dailyCheckInPagination.next(pagination)
  }

  getDailyCheckInPagination(): IPagination {
    return Object.assign({}, this.dailyCheckInPagination.getValue())
  }

  setDailyCheckinBankPagination(pagination: IPagination): void {
    this.dailyCheckInPagination.next(pagination)
  }

  getDailyCheckInBankPagination(): IPagination {
    return Object.assign({}, this.dailyCheckInPagination.getValue())
  }

  getDailyCheckInBankTemplate(): IDailyCheckInTemplate {
    return Object.assign({}, this.dailyCheckinBankTemplate.getValue())
  }

  setDailyCheckinBankTemplate(template: IDailyCheckInTemplate) {
    console.log(template);

    this.dailyCheckinBankTemplate.next(template)
  }

  setDailyCheckinTemplate(dailyCheckinTemplate: IDailyCheckInTemplate): void {
    console.log(dailyCheckinTemplate);
    this.dailyCheckinTemplate.next(dailyCheckinTemplate)
  }

  getDailyCheckinTemplate(): IDailyCheckInTemplate {
    return Object.assign({}, this.dailyCheckinTemplate.getValue())
  }

  setDailyCheckinTemplateResponse(response: IDailyCheckInTemplateResponse): void {
    this.setDailyCheckinTemplate(response.result.template)
    this.setDailyCheckinPagination(response.metadata.pagination)
  }

  setDailyCheckinBankResponse(response : IDailyCheckInTemplateResponse): void {
    this.setDailyCheckinBankPagination(response.metadata.pagination)
    this.setDailyCheckinBankTemplate(response.result.template)
  }


  /**
   * Fetches daily check in responses from the client-facing API.
   * @param clientId The ID of the client whose responses you are fetching
   * @param startDate The earliest date you want to fetch
   * @param endDate The latest date you want to fetch
   * @param groupResponsesBy Determines which request to make
   * @param page The page to return
   */
  fetchDailyCheckIns(
    clientId: number,
    startDate: string,
    endDate: string,
    groupResponsesBy: 'date'|'question',
    page = 1): Promise<any> {

    return new Promise((res, rej) => {
      if (groupResponsesBy === 'date') {
        this.fetchDailyCheckinsByDate(clientId, startDate, endDate, page).subscribe(
          data => res(data),
          err => rej(err)
        )
      } else if (groupResponsesBy === 'question') {
        this.fetchDailyCheckinsByQuestion(clientId, startDate, endDate, page).subscribe(
          data => {
            console.log(data)
            res(data)
          },
          err => rej(err)
        )
      }
    })

  }


  /**
   * Fetches daily check in responses from the client-facing API.
   * @param clientId The ID of the client whose responses you are fetching
   * @param startDate The earliest date you want to fetch
   * @param endDate The latest date you want to fetch
   * @param page The page to return
   */
  fetchDailyCheckinsByDate(clientId: number, startDate: string, endDate: string, page = 1): Observable<IDailyCheckInsResponse> {
    const url =
      `${this.baseUrl}/clients/${clientId}/daily_checkins?start_date=${startDate}&end_date=${endDate}&page=${page}&group_by=date`
    return this.http.get<IDailyCheckInsResponse>(url).pipe(tap(response => {
      this.setDailyCheckInResponse(response)
    }))
  }


  /**
   * Fetches daily check in responses from the client-facing API.
   * @param clientId The ID of the client whose responses you are fetching
   * @param startDate The earliest date you want to fetch
   * @param endDate The latest date you want to fetch
   * @param page The page to return
   */
  fetchDailyCheckinsByQuestion(clientId: number, startDate: string, endDate: string, page = 1): Observable<IDailyCheckInsResponse> {
    const url =
      `${this.baseUrl}/clients/${clientId}/daily_checkins?start_date=${startDate}&end_date=${endDate}&page=${page}&group_by=question`
    return this.http.get<IDailyCheckInsResponse>(url).pipe(tap(response => {
      this.setDailyCheckInResponse(response)
    }))
  }


  fetchDailyCheckinAnswersByQuestion(
    question: IDailyCheckInQuestion,
    startDate: string,
    endDate: string,
    clientId: number,
    page = 1): Observable<IDailyCheckinByQuestionAnswerResponse> {
    const url = `
      ${this.baseUrl}/daily_checkin_answers/fetch_by_question?client_id=${clientId}&start_date=${startDate}&end_date=${endDate}&page=${page}
    `
    return this.http.post<IDailyCheckinByQuestionAnswerResponse>(url, {daily_checkin_question: question})
  }


  /**
   * Fetches the client's daily checkin templates from the client-facing API
   * @param clientId The ID of the client whose responses you are fetching
   * @param page The page to return
   */
  fetchDailyCheckinTemplate(clientId: number, page = 1): Observable<IDailyCheckInTemplateResponse> {
    const url = `${this.baseUrl}/daily_checkin_templates?page=${page}&client_id=${clientId}`
    return this.http.get<IDailyCheckInTemplateResponse>(url).pipe(tap(response => {
      this.setDailyCheckinTemplateResponse(response)
    }))
  }


  fetchDailyCheckinQuestionByTemplateType(page: number, templateType: string): Observable<IDailyCheckInTemplateResponse> {
    const url = `${this.baseUrl}/daily_checkin_templates?page=${page}&template_type=${templateType}`
    return this.http.get<IDailyCheckInTemplateResponse>(url).pipe(tap(response => {
      if (templateType === 'bank') this.setDailyCheckinBankResponse(response)
    }))
  }


  addQuestionToBank(question: IDailyCheckInQuestion): Observable<IDailyCheckInTemplateResponse>  {
    const url = `${this.baseUrl}/daily_checkin_questions/add_question_to_bank`
    return this.http.post<IDailyCheckInTemplateResponse>(url, question).pipe(tap(response => {
      this.setDailyCheckinBankResponse(response)
    }))
  }


  createDailyCheckinQuestion(question: IDailyCheckInQuestion, clientId: number): Observable<IDailyCheckInQuestionResponse> {
    const url = `${this.baseUrl}/clients/${clientId}/daily_checkin_questions`
    const formattedQuestion = {daily_checkin_question: question}
    return this.http.post<IDailyCheckInQuestionResponse>(url, formattedQuestion).pipe(tap(response => {
      const template = this.getDailyCheckinTemplate()
      const dailyCheckinQuestion = new DailyCheckInQuestion(response.result)
      template.questions.unshift(dailyCheckinQuestion)
      this.setDailyCheckinTemplate(template)
    }))
  }

  destroyQuestion(question: IDailyCheckInQuestion, clientId: number): Observable<IDailyCheckInTemplateResponse> {
    const url = `${this.baseUrl}/clients/${clientId}/daily_checkin_questions/${question.id}`
    return this.http.delete<IDailyCheckInTemplateResponse>(url).pipe(tap(response => {
      if (question.seed_type === 'bank') {
        this.setDailyCheckinBankResponse(response)
      } else {
        this.setDailyCheckinTemplateResponse(response)
      }
    }))
  }

  updateDailyCheckinQuestion(question: IDailyCheckInQuestion, clientId: number): Observable<IDailyCheckInTemplateResponse> {
    const url = `${this.baseUrl}/clients/${clientId}/daily_checkin_questions/${question.id}`
    console.log('qqq', question)
    return this.http.put<IDailyCheckInTemplateResponse>(url, question).pipe(tap(response => {
      this.setDailyCheckinTemplateResponse(response)
    }))
  }
}
