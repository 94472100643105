import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { AwakenModal } from 'src/app/shared/awaken-modal/awaken-modal.component';

@Component({
  selector: 'show-document',
  templateUrl: 'show-document.modal.html'
})

export class ShowDocumentModal implements OnInit {
  // @Output() storeDocumentSelected: EventEmitter<void> = new EventEmitter<void>();
  @Input() document_url: string;
  @Input() document_name: string;
  @Input() base64file: string;
  @Input() showFullscreen: boolean = true;
  @Input() isClientDoc: boolean = false;
  @Input() canSend: boolean = false;
  @Input() fileType: string;
  iframeHeight: string;
  pdfData: any;
  safeUrl: SafeUrl;

  constructor( private modalCtrl: ModalController,
               public sanitization: DomSanitizer ) {
    console.log(this)
  }

  ngOnInit() {
    if (this.document_url) {
      this.safeUrl = this.sanitization.bypassSecurityTrustResourceUrl(this.document_url)
    }
  }

  dismiss(showFullscreen = false) {
    this.modalCtrl.dismiss({ showFullscreen })
  }

  ionViewDidEnter(): void {
    setTimeout(() => {
      const iFrame = document.getElementById( 'iframeContainer' );
      if (iFrame.getClientRects().length) {
        this.iframeHeight = `${iFrame.getClientRects()[0].height}px`
      }
    }, 250)
  }

  async confirmSelection(title: string, subtitle: string = null): Promise<any> {
    const modal = await this.modalCtrl.create({
      component: AwakenModal,
      componentProps: {
        type: 'confirm',
        subtitle,
        title,
      },
      cssClass: 'small-modal'
    })
    await modal.present()
    return await modal.onDidDismiss()
  }

  async sendDocument(): Promise<void> {
    const result = await this.confirmSelection('Are you sure you want to send this document?', 'Sending this document will send it to the client immediately.')
    if (result?.data === 'yes') this.modalCtrl.dismiss({ sendDocument: true })
  }

  async storeDocument() {
    const result = await this.confirmSelection('Are you sure you want to save this document?')
    if (result?.data === 'yes') this.modalCtrl.dismiss({ storeDocument: true })
  }
}
